import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import {UserContext} from "../../context/UserContext";

import Card from "../../components/Card";
import Loader from "../../components/Loader";
import {Dropdown} from 'react-bootstrap';

class Members extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      members: null,
      filtered_members: null,
      filter_text: '',
      membership_filter: null,
      sort: 'Alfabetisch Achternaam'
    };

    this.changeFilterText = this.changeFilterText.bind(this);
    this.clickAll = this.clickAll.bind(this);
    this.clickMembershipPaid = this.clickMembershipPaid.bind(this);
    this.clickMembershipUnpaid = this.clickMembershipUnpaid.bind(this);
    this.filter = this.filter.bind(this);
    this.export = this.export.bind(this);
  }

  componentDidMount() {
    this.fetchMembers();
  }

  fetchMembers() {
    axios.get("/api/admin/members")
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          this.setState({
            members: response.data.users,
            filtered_members: response.data.users
          }, this.filter);
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon de leden niet ophalen.");
      });
  }

  blockMember(id, user) {
    if (user.id === id) {
      toast.error("Je kan jezelf niet blokkeren!");
      return;
    }

    axios.post('/api/admin/members/block', {
      user_id: id
    }).then((response) => {
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success("Gebruiker geblokkeerd!");
        this.fetchMembers();
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Kon de aanvraag niet verwerken, probeer nog eens!");
    });
  }

  export() {
    axios.get("/api/admin/members/export")
      .then((response) => {
        if (!response.data.success) {
          toast.error("Kon export niet downloaded");
        } else {
          window.open('/' + response.data.url, "_blank");
        }
      }).catch((error) => {
        console.log(error);
        toast.error("Kon export niet downloaded");
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={1} />
          <Card md={10}>
            <h1>Overzicht Leden</h1>
            <p>In deze lijst kan je alle leden zien die momenteel een account hebben. Als je op bekijken klikt kan je hun profiel bekijken en bijwerken.</p>
          </Card>
        </Row>
        <Row>
          <Col md={1} />
          <Card md={10}>
            {(this.state.filtered_members) ? (
              <div>
                <div className="form-group">
                  <input type="text" placeholder="Zoek leden" className="form-control" onChange={this.changeFilterText} value={this.state.filter_text}/>
                </div>
                <div className="d-flex flex-row mobile-center">
                  <Button className="mr-3 mb-3" onClick={this.clickAll}>Toon alle leden</Button>
                  <Button className="mr-3 mb-3" onClick={this.clickMembershipPaid} variant={(this.state.membership_filter === "PAID") ? 'success' : 'primary'}>Toon lidgeld betaald</Button>
                  <Button className="mr-3 mb-3" onClick={this.clickMembershipUnpaid} variant={(this.state.membership_filter === "UNPAID") ? 'success' : 'primary'}>Toon lidgeld niet betaald</Button>
                  <Button className="mr-3 ml-auto mb-3" onClick={this.export} variant={'info'}>Export in Excel</Button>
                </div>
                <div className="d-flex flex-row mobile-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      { this.state.sort }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => this.changeSort('Alfabetisch Achternaam')}>Alfabetisch Achternaam</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.changeSort('Alfabetisch Voornaam')}>Alfabetisch Voornaam</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.changeSort('Datum Registratie')}>Datum Registratie</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.changeSort('Datum Lidgeld Betaald')}>Datum Lidgeld Betaald</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="table-list">
                  {this.getMembers(this.state.filtered_members)}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            )}
          </Card>
        </Row>
      </div>
    )
  }

  getMembers(members) {
    return (<UserContext.Consumer>
      {({user}) => (
        members.map((member) => {
          const url = "/profile/" + member.user_id;

          return (
            <div key={member.user_id} className="d-flex justify-content-between flex-with-buttons">
              <p>{ member.name } { member.first_name }</p>
              <div>
                <Button variant="info" as={Link} to={url} className="mr-3">Bekijk</Button>
                <Button variant="danger" className="mr-3" onClick={() => this.blockMember(member.user_id, user)}>Blokeer</Button>
              </div>
            </div>
          )
        })
      )}
    </UserContext.Consumer>);
  }

  changeFilterText(event) {
    this.setState({
      filter_text: event.target.value
    }, this.filter);
  }

  clickAll() {
    this.setState({
      filtered_members: this.state.members,
      filter_text: '',
      membership_filter: null
    }, this.filter);
  }

  clickMembershipPaid() {
    this.setState({
      membership_filter: "PAID"
    }, this.filter);
  }

  clickMembershipUnpaid() {
    this.setState({
      membership_filter: "UNPAID"
    }, this.filter);
  }

  filter() {
    let members = this.state.members;

    if (this.state.membership_filter) {
      if (this.state.membership_filter === "PAID") {
        members = members.filter(member => {
          return member.membership_valid > new Date().getTime()
        });
      } else {
        members = members.filter(member => {
          return member.membership_valid < new Date().getTime()
        });
      }
    }

    let filtered = members;

    if (this.state.filter_text) {
      filtered = members.filter(member => {
        const name = member.name + " " + member.first_name;
        return name.toLocaleLowerCase().indexOf(this.state.filter_text.toLocaleLowerCase()) > -1
      });
    }

    filtered.sort((a, b) => {
      switch (this.state.sort) {
        case 'Alfabetisch Achternaam':
          const fullNameA = a.name.toUpperCase() + " " + a.first_name.toUpperCase(); // ignore upper and lowercase
          const fullNameB = b.name.toUpperCase() + " " + b.first_name.toUpperCase(); // ignore upper and lowercase

          return this.sortName(fullNameA, fullNameB);

        case 'Alfabetisch Voornaam':
          const firstNameA = a.first_name.toUpperCase() + " " + a.name.toUpperCase(); // ignore upper and lowercase
          const firstNameB = b.first_name.toUpperCase() + " " + a.name.toUpperCase(); // ignore upper and lowercase

          return this.sortName(firstNameA, firstNameB);

        case 'Datum Registratie':
          return a.user_id - b.user_id;

        case 'Datum Lidgeld Betaald':
          return Number(a.membership_paid) - Number(b.membership_paid);

        default:
          return 0;
      }
    })

    this.setState({
      filtered_members: filtered
    });
  }

  sortName(nameA, nameB) {
    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }

  changeSort(sort) {
    this.setState({
      sort
    }, this.filter);
  }
}

export default Members;